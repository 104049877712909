import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, map } from "lodash";
import {
  commaFormatted,
  normalizeAmount,
  formatData,
} from "../../../../utils/transformer.util";
import {
  Select,
  Input,
  Button,
  Pagination,
  InputDate,
  SearchSelect,
} from "../../../../components/base";
import {
  EDIT_TAGIHAN_SISWA_FIELD_LIST,
  RIWAYAT_OFFLINE_TABLE_FIELD_LIST,
  RIWAYAT_H2H_TABLE_FIELD_LIST,
} from "../../../../constants/finance/spp/create_invoice.constant";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import ReactToPrint from "react-to-print";
import language from "../../../../languages";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

const MySwal = withReactContent(Swal);

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class KartuBayarSiswa extends PureComponent {
  constructor(props) {
    super(props);

    this._onFormChange = this._onFormChange.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    // this._getStudentSPPOptions = this._getStudentSPPOptions.bind(this);
    // this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this._getUnitsVaCodeOptions = this._getUnitsVaCodeOptions.bind(this);
    this._getMsTempsSiswaOptions = this._getMsTempsSiswaOptions.bind(this);
    this._getPrmStudentGeneralOptions =
      this._getPrmStudentGeneralOptions.bind(this);
    this._getSchoolUnitsOptions = this._getSchoolUnitsOptions.bind(this);
    this.getPrmPaymentsNon = this.getPrmPaymentsNon.bind(this);
    this.getPrmPaymentsNonGeneral = this.getPrmPaymentsNonGeneral.bind(this);
    this._onClickEditNon = this._onClickEditNon.bind(this);
    this._getPeriodsOptions = this._getPeriodsOptions.bind(this);

    const { user } = this.props;
    const { school_unit } = user;
    const { id, levels_id } = school_unit;
    this.state = {
      expanded: null,
      page: 1,
      selected: {},
      content: initialContent,
      unchecked: {},
      list: [],
      filters: {},
      form: {
        value: {
          total: "",
          classes: [],
          levels_id,
          periods: "",
          listhistory: [],
          classes_id: "",
          pg_code: "",
          payload: [],
          listsiswa: [],
          listcicilan: [],
          listsiswa2: [],
          user_id: user.id,
        },
        error: {
          classes: "",
          levels: "",
          listhistory: "",
          listsiswa: "",
          listcicilan: "",
          listsiswa2: "",
        },
      },
      param: {},
      filtered: false,
    };
    this.checkboxRefs = [];
  }

  handleChange = (panel) => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  componentDidMount() {
    const { user } = this.props;
    const { school_unit, organizations_id, user_group } = user;
    const { content, filters, form } = this.state;
    let savedFilters =
      JSON.parse(window.localStorage.getItem("invoiceStudentsFilters")) ||
      filters;
    let savedForm =
      JSON.parse(window.localStorage.getItem("invoiceStudentsForm")) || "";
    if (savedForm != "" && user.id != savedForm.value.user_id) {
      savedForm = "";
      savedFilters = filters;
    }

    console.log(savedFilters);
    console.log(savedForm);

    if (isEmpty(content.list)) {
      // this._onSearchContent({ page: 1 });
    }
    this._initializeData(savedFilters);
    if (savedForm != "") {
      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          value: {
            ...prevState.form.value,
            units_id: savedForm.value.units_id,
            update: true,
          },
        },
        filters: {
          ...prevState.filters,
          students_id: savedFilters.students_id,
        },
      }));

      this._getPeriodsOptions({ units_id: savedForm.value.units_id.value });

      if (organizations_id == 3) {
        // this._getUnitsVaCodeOptions();
        this._getMsTempsSiswaOptions({
          units_id: savedForm.value.units_id.attributes.va_code,
        });
      } else {
        // this._getSchoolUnitsOptions();
        this._getPrmStudentGeneralOptions({
          units_id: savedForm.value.units_id,
        });
      }
    }
  }

  async _initializeData(savedFilters = {}) {
    const { user } = this.props;
    const { form } = this.state;
    const { school_unit, user_group, organizations_id } = user;

    await this._getSchoolUnitsOptions();

    if (organizations_id == 3) {
      await this.getPrmPaymentsNon();
    } else {
      await this.getPrmPaymentsNonGeneral();
    }
    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          students_id: savedFilters.students_id,
          // units_id : savedFilters.units_id,
        },
      }),
      () => {
        this._onSearchContent();
      }
    );
  }

  async _getPrmStudentGeneralOptions(filters = {}) {
    const { getPrmStudentGeneralOptions } = this.props;
    const res = await getPrmStudentGeneralOptions(filters);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        student: res,
      },
    }));
  }

  async _getSchoolUnitsOptions(filters = {}) {
    const { getSchoolUnitsOptions } = this.props;
    const { form } = this.state;
    const { units_id } = form.value;
    const res = await getSchoolUnitsOptions(filters);
    const selectedUnits = res.find((item) => item.value == units_id);

    // console.log(units_id)
    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          levels_units: selectedUnits ? selectedUnits.attributes.levels_id : "",
        },
      },
      param: {
        ...prevState.param,
        units: res,
      },
    }));
  }

  async _getMsTempsSiswaOptions(filters = {}) {
    const { getMsTempsSiswaOptions } = this.props;
    const res = await getMsTempsSiswaOptions(filters);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        student: res,
      },
    }));
  }

  async _getUnitsVaCodeOptions(filters = {}) {
    const { getUnitsVaCodeOptions } = this.props;
    const { form } = this.state;
    const { units_id } = form.value;
    const res = await getUnitsVaCodeOptions(filters);

    // console.log(units_id)
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        units: res,
      },
    }));
  }

  _onClickEdit(val) {
    const { filters, param } = this.state;
    const { students_id } = filters;
    const { history } = this.props;

    let student_name = "";
    let student_va = "";

    if (!isEmpty(students_id)) {
      let str = students_id.label;
      let result = str.indexOf("-");
      student_name = str.substring(result + 2, str.length);
      student_va = str.substring(0, result - 1);
    }
    // console.log("ini val" +val)
    history.push("/dashboard/tagihan-siswa/create-invoice/rutin", {
      getdata: val,
      isEdit: true,
      data: {
        user_id: 0,
        name: filters.students_id.label,
        id: filters.students_id.value,
        name: student_name,
        no_va: student_va,
        entrance_year: students_id.academics_year,
      },
    });
  }

  _onClickEditNon(val) {
    const { history, user } = this.props;
    const { organizations_id } = user;
    // console.log("ini val" +val)
    if (organizations_id == 4 || organizations_id == 5) {
      history.push(
        "/dashboard/tagihan-siswa/create-invoice/tidak-rutin-general",
        { data: val, isEdit: true }
      );
    } else {
      history.push("/dashboard/tagihan-siswa/create-invoice/tidak-rutin", {
        data: val,
        isEdit: true,
      });
    }
  }

  _onFormChange(event) {
    const { name, value, dataset } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState(
      (prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;

        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {
          newList = prevState.form.value[fieldName];
          newListError = prevState.form.error[fieldName];
          newList[arrayPosition][name] = formattedValue;
          if (name === "code_of_account") {
            newList[arrayPosition].isCredit = value.type;
          }
          if (!isEmpty(newListError[arrayPosition])) {
            newListError[arrayPosition][name] = "";
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
      () => {}
    );
  }

  async _getPeriodsOptions(filters = {}) {
    const { getPeriodsOptions } = this.props;
    const res = await getPeriodsOptions(filters);

    map(res, (data, idx) => {
      var date = new Date();
      var start_date = new Date(data.attributes.start_date);
      if (date > start_date) {
        // tahun ajaran sekarang
        this.setState((prevState) => ({
          ...prevState,
          filters: {
            ...prevState.filters,
            period: data.value,
          },
        }));
      }
    });

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    const { user } = this.props;
    const { organizations_id, user_group } = user;
    const { filters, param } = this.state;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => {
        window.localStorage.setItem(
          "invoiceStudentsFilters",
          JSON.stringify(this.state.filters)
        );
        window.localStorage.setItem(
          "invoiceStudentsForm",
          JSON.stringify(this.state.form)
        );
        if (name === "units_id") {
          delete filters.students_id;
          const param = this.state.param.units;

          const va_code = param.find((i) => i.value == value.value)
            ? param.find((i) => i.value == value.value).attributes.va_code
            : "";

          if (organizations_id == 3) {
            this._getMsTempsSiswaOptions({ units_id: va_code });
          } else {
            this._getPrmStudentGeneralOptions({ units_id: [value.value] });
          }
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                units_id: value,
              },
            },
            filters: {
              ...prevState.filters,
              students_id: [],
            },
          }));
        }
        this._onSearchContent();
      }
    );
  }

  onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this._onSearchContent({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  async _onSearchContent(params = {}) {
    const { filters, form } = this.state;
    const { students_id, jenis, payments_id } = filters;
    const { value } = form;
    const { subject_id, currents_year } = value;
    console.log(filters.students_id);
    if (filters.date === "") {
      delete filters.date;
    }
    if (filters.date_to === "") {
      delete filters.date_to;
    }

    this.setState(
      {
        content: initialContent,
      },
      async () => {
        try {
          const { handleStudentCardGeneral } = this.props;
          const { user } = this.props;
          const { school_unit, organizations_id } = user;
          var year = new Date().getFullYear();
          var month = new Date().getMonth() + 1;
          if (month < 7) {
            year -= 1;
          }
          let result = await handleStudentCardGeneral({
            ...params,
            temps_id: students_id.value,
            currents_year: currents_year ? currents_year : year,
            filters,
          });

          // untuk set state list payment gateway dan offline
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                listsiswa: result,
              },
            },
          }));
        } catch (err) {
          // if (this.isMount) {
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                listsiswa: [],
              },
            },
          }));
        }
      }
    );
  }

  async _getStudentSPPOptions(filters = {}) {
    const { getStudentSPPOptions } = this.props;
    const res = await getStudentSPPOptions(filters);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        student: res,
      },
    }));
  }

  async getPrmPaymentsNon() {
    const { getPrmPaymentsNon } = this.props;
    const res = await getPrmPaymentsNon();
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        prm_payments: res,
      },
    }));
  }

  async getPrmPaymentsNonGeneral() {
    const { getPrmPaymentsNonGeneral } = this.props;
    const res = await getPrmPaymentsNonGeneral();
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        prm_payments: res,
      },
    }));
  }

  render() {
    const { param, form, content, filters, listH2h } = this.state;
    const {
      date = "",
      date_to = "",
      payments_type,
      students_id,
      jenis,
      payments_id,
      period,
    } = filters;
    const { listsiswa, listcicilan, listsiswa2 } = form.value;
    const { user } = this.props;
    const { organizations_id, user_group } = user;
    const paymentstype = [
      { label: "Payment Gateway", value: "paymentgateway" },
      { label: "Offline", value: "offline" },
      { label: "H2H", value: "h2h" },
    ];
    const { prm_payments, student } = param;
    var count = 0;

    const bulan = [
      { label: "Juli" },
      { label: "Agustus" },
      { label: "September" },
      { label: "Oktober" },
      { label: "November" },
      { label: "Desember" },
      { label: "Januari" },
      { label: "Februari" },
      { label: "Maret" },
      { label: "April" },
      { label: "Mei" },
      { label: "Juni" },
    ];

    if (date > date_to) {
      form.error.date = "Format Tanggal Salah";
      form.error.date_to = "Format Tanggal Salah";
    } else {
      form.error.date = "";
      form.error.date_to = "";
    }
    let no_va = student
      ? student.find((item) => item.value == students_id.value)
        ? student.find((item) => item.value == students_id.value).value
        : ""
      : "";
    let label = student
      ? student.find((item) => item.value == students_id.value)
        ? student.find((item) => item.value == students_id.value).label
        : ""
      : "";
    let student_name = label.slice(14);
    let classrooms_name = student
      ? student.find((item) => item.value == students_id.value)

      ?student.find((item) => item.value == students_id.value).classrooms
      :""
      : "";
    console.log(classrooms_name);
    const prm_jenis = [
      { label: organizations_id == 3 ? "SPP" : "RUTIN", value: "rutin" },
      {
        label: organizations_id == 3 ? "DPP/UPP" : "NON RUTIN",
        value: "nonrutin",
      },
    ];

    const prm_period = param.periods ? param.periods : "";
    const period_label = prm_period
      ? prm_period.find((i) => i.value == period)
        ? prm_period.find((i) => i.value == period).label
        : ""
      : "";
    if (!isEmpty(param.periods) && period) {
      let period_label = param.periods.find((i) => i.value == period)
        ? param.periods.find((i) => i.value == period).label
        : "";
      if (period_label && period_label != "") {
        let period_idx = period_label.indexOf("-");
        form.value.currents_year = period_label.substring(0, period_idx);
      }
    }
    const data_inv = listsiswa ? listsiswa.data_inv : "";
    
    // console.log(ttlUSK);
    const componentRef = React.createRef();
    const printTest = `
    @media print {
      @page {
        page-break-inside: avoid;
      }
      * {
        -webkit-print-color-adjust: exact;
      }
    }`;

    return (
      <div className="manage-registration">
        <div className="budget__title">
          <h1>Kartu Bayar Siswa</h1>
          <hr></hr>
        </div>
        <div className="absensi-rekap__custom-form">
          <div className="absensi-rekap__custom-form-row">
            <div className="absensi-rekap__custom-form-row__field">
              <SearchSelect
                noMargin
                async={false}
                name="units_id"
                list={param.units}
                inputArray
                onClick={this._onChangeFilter}
                placeholder="Pilih Unit"
                value={form.value.units_id}
                rightIcon="icon-search"
                label="Pilih Unit"
              />
            </div>
            <div className="absensi-rekap__custom-form-column__field">
              <SearchSelect
                noMargin
                async={false}
                name="students_id"
                list={param.student}
                inputArray
                onClick={this._onChangeFilter}
                placeholder="Pilih Siswa"
                value={students_id}
                // value="061190207"
                rightIcon="icon-search"
                label="Siswa"
              />
            </div>
          </div>
          <div className="absensi-rekap__custom-form-row">
            <div className="absensi-rekap__custom-form-row__field">
              <Select
                name="period"
                label="Tahun Ajaran"
                onChange={this._onChangeFilter}
                data={param.periods}
                placeholder="Pilihan"
                value={period}
              />
            </div>
            <div className="absensi-rekap__custom-form-column__field"></div>
          </div>
          <br></br>
        </div>
        <div className="beginning-balance__button-wrapper">
          {/* <ReactToPrint
            pageStyle={printTest}
            onBeforeGetContent={() => {
              return new Promise((resolve) => {
                setTimeout(() => {
                  this.setState({ isPrint: false }, resolve);
                }, 500);
              });
            }}
            onAfterPrint={() => this.setState({ isPrint: true })}
            trigger={() => (
              // <div className="manage-registration__footer">
              <Button title="Cetak/Simpan PDF" />
              // </div>
            )}
            content={() => componentRef.current}
          /> */}
          <ReactHTMLTableToExcel
            id="export-to-xls-button"
            className="button "
            table="printable"
            filename={`Kartu Bayar Siswa ${student_name}`}
            // sheet={period_label ? period_label : "-"}
            buttonText="Unduh ke Excel"
          />
          &nbsp;
        </div>
        <table className="table-studentCard" style={{ width: "50%" }} ref={componentRef} id="printable">
          <table className="table-studentCard" style={{ width: "unset" }} id="printable">
            <tr>
              <td>No. VA </td>
              <td>&emsp; : {no_va}</td>
            </tr>
            <tr>
              <td>Nama Siswa</td>
              {/* <td>&emsp; : {form.value.currents_year ? form.value.currents_year+"/"+(Number(form.value.currents_year)+1) : year+"/"+(Number(year)+1)}</td> */}
              <td>&emsp; : {student_name} </td>
            </tr>
            <tr>
              <td>Kelas</td>
              <td>&emsp; : {classrooms_name}</td>
            </tr>
          </table>

          <table className="table-studentCard" id="printable">
            <tr>
              <th colSpan={2} style={{ textAlign: "center" }}>
                Data Kewajiban
              </th>
            </tr>
            {map(listsiswa.data_inv, (item, idx) => (
              <>
                <tr>
                  <td style={{ textAlign: "left" }}>{item.name}</td>
                  <td style={{ textAlign: "left" }}>
                    Rp. {commaFormatted(item.total)}
                  </td>

                  <td style={{ textAlign: "left" }}></td>
                </tr>
              </>
            ))}
            <hr style={{ width: "145%" }}></hr>
            {map(listsiswa.data_disc, (item, idx) => (
              <>
                <tr>
                  <td style={{ textAlign: "left" }}>{item.name}</td>
                  <td style={{ textAlign: "left" }}>
                    Rp. -{commaFormatted(item.total_disc)}
                  </td>

                  <td style={{ textAlign: "left" }}></td>
                </tr>
              </>
            ))}
            {/* <tfoot> */}
              <tr className="total_k">
                <td >Total:</td>
                <td>Rp. {commaFormatted(listsiswa.total_inv)}</td>
              </tr>
            {/* </tfoot> */}
              
            
          </table>
          <table className="table-studentCard2">
            <thead>
              <tr>
                <th rowSpan={2}>Bulan</th>
                <th colSpan={3}>Nominal</th>
                <th rowSpan={2}>Tanggal Bayar</th>
              </tr>
              <tr>
                <th>USEK</th>
                <th>UKEG</th>
                <th>DPP</th>
              </tr>
            </thead>
            <tbody>
              {map(listsiswa.tbl_inv, (data, idx) => (
                <tr>
                  <td>{data.month}</td>
                  <td>{data.invUSK != 0 ? commaFormatted(data.invUSK) : 0}</td>
                  <td>{data.invUKG != 0 ? commaFormatted(data.invUKG) : 0}</td>
                  <td>{data.invDPP != 0 ? commaFormatted(data.invDPP) : 0}</td>
                  <td>{data.payments_date}</td>
                </tr>
              ))}
              <tr>
                <td> Total: </td>
                <td> {commaFormatted(listsiswa.ttl_USK)}</td>
                <td> {commaFormatted(listsiswa.ttl_UKG)}</td>
                <td> {commaFormatted(listsiswa.ttl_DPP)}</td>
                <td> </td>
              </tr>
              <tr>
                <td> Sisa Pembayaran: </td>
                <td> {commaFormatted(listsiswa.sisa_USK)}</td>
                <td> {commaFormatted(listsiswa.sisa_UKG)}</td>
                <td> {commaFormatted(listsiswa.sisa_DPP)}</td>
              </tr>
            </tbody>
          </table>
        </table>
      </div>
    );
  }
}
KartuBayarSiswa.propTypes = {
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
KartuBayarSiswa.defaultProps = {
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};
