import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop } from 'lodash';
import { IDENTITAS_ADD_DATAKELAS_FORM_FIELDS } from '../../../constants/Akademi/DataKelas/identitas.constant';
import { Button } from '../../../components/base';
import IdentitasSekolah1 from './components/IdentitasSekolah1.component';
import IdentitasSekolah2 from './components/IdentitasSekolah2.component';
import * as commonActions from '../../../states/actions/common.action';
import { validateRequiredFields } from "../../../../src/utils/validation.util";

export default class TambahIdentitas extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._onClickNext = this._onClickNext.bind(this);
    this._onClickBack = this._onClickBack.bind(this);
    this._getParamOptions = this._getParamOptions.bind(this);
    this.onRemoveFile = this.onRemoveFile.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this._getDistrictOptions = this._getDistrictOptions.bind(this);
    this._getTeacherOptions = this._getTeacherOptions.bind(this);
    this._setForm = this._setForm.bind(this);

    const { user } = this.props;
    const { school_unit } = user;
    const { units_id, levels_id } = school_unit;

    this.state = {
      page: 1,
      form: {
        value: {
          levels_id,
        },
        error: {
          plafon: '',

        },
      },
      param: {},
    };
  }

  componentDidMount() {
    const {handleGetProvinces, handleGetCities, handleGetDistricts, handleGetSubDistricts, location, user} = this.props;
    const { state = {} } = location;
    const { school_unit } = user;
    const { units_id, levels_id } = school_unit;
    const { isEdit =  false, data } = state;
    const { id } = state.data || '';
    const paramTypes = ['unit_types', 'nisn_options', 'report_periods', 'highest_grade', 'citizenships', 'religions', 'genders', 'levels'];
    paramTypes.forEach((type) => {
      this._getParamOptionsAcademics(type);
    });
    if (isEdit && id) {
      this.setState({id:id})
      this._setForm(data.id);
    }
    // handleGetProvinces();
    // handleGetCities();
    // handleGetDistricts();
    // handleGetSubDistricts();
    // this._getDistrictOptions();
    this._getTeacherOptions({units_id: [school_unit.id] });
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { school_unit, organizations_id } = user;
    if (type === "classrooms" || type === "classrooms") {
      filters.organizations_id = organizations_id;
      filters.units_id = school_unit.id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  _onFormChange(event) {
    const {
      name,
      value,
      dataset,
      checked,
      type,
      files
    } = event.target;
    const {
      inputType = 'text', inputArray = false, arrayPosition = 0,
      fieldName,
    } = dataset;

    const {handleGetProvinces, handleGetCities, handleGetDistricts, handleGetSubDistricts} = this.props;
    let keyword = null;
    // if(name === 'provinces_id') {
    //   handleGetCities({ filters: { province_id: event.target.value}})
    // }
    // if(name === 'cities_id') {
    //   handleGetDistricts({ filters: { city_id: event.target.value}})
    // }
    // if(name === 'districts_id' ) {
    //   handleGetSubDistricts({
    //      filters: { district_id: event.target.value },
    //      keyword
    //     })
    // }

    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;

      if (inputArray) {
        if (type === "file") {
          formattedValue = files[0];
        }
        if (type === 'checkbox') {
          formattedValue = checked;
        }
        newList = prevState.form.value[fieldName];
        newListError = prevState.form.error[fieldName];
        newList[arrayPosition][name] = formattedValue;
        if (name === 'code_of_account') {
          newList[arrayPosition].isCredit = value.type;
        }
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = '';
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray
              ? { [fieldName]: newListError }
              : { [name]: '' }),
          },
        },
      };
    });
  }



  _onClickNext() {
    window.scrollTo(0, 0);
    this.setState(prevState => ({
      page: prevState.page + 1,
    }));
  }

  _onClickBack() {
    window.scrollTo(0, 0);
    this.setState(prevState => ({
      page: prevState.page - 1,
    }));
  }

  async _getParamOptions(type, filters={}) {
    const { getParamOptions } = this.props;
    const res = await getParamOptions(filters, type);
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }


  async _onSubmit() {
    const { history, handleSaveUnits } = this.props;
    const { form } = this.state;
    const { value } = form;

    const error = validateRequiredFields(form.value, [
      "name",
      "levels_id",
      "phone",
      "email",
      "address",
      "provinces",
      "cities",
    ]);
    this.setState((prevState) => ({
      form: {
        value: prevState.form.value,
        error,
      },
    }));

    if (isEmpty(error)) {
      await handleSaveUnits(value);
      history.push('/dashboard/pengaturan/identitas-unit-sekolah');
    }
  }

  async onEdit() {
    const { history, handleEditUnits } = this.props;
    const { form } = this.state;
    const { value } = form;

    const error = validateRequiredFields(form.value, [
      "name",
      "levels_id",
      "phone",
      "email",
      "address",
      "provinces",
      "cities",
    ]);
    this.setState((prevState) => ({
      form: {
        value: prevState.form.value,
        error,
      },
    }));

    if (isEmpty(error)) {
      const res = await handleEditUnits(value);        
      // history.push('/dashboard/pengaturan/identitas-unit-sekolah');
      window.location.href='/dashboard/pengaturan/identitas-unit-sekolah';
    }
  }

  async _getDistrictOptions() {
    const {
      handleGetCities,
      handleGetDistricts,
      handleGetProvinces,
      handleGetSubDistricts
    } = this.props;
    const { param } = this.state;

    const provinces = await handleGetProvinces();
    const cities = await handleGetCities();
    const districts = await handleGetDistricts();
    const subDistricts = await handleGetSubDistricts();
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        provinces: provinces,
        cities: cities,
        districts: districts,
        subDistricts: subDistricts,
      },
    }));
   
  }

  async _getTeacherOptions(filters ={}) {
    const { getTeacherOptions, user } = this.props;
    const { school_unit } = user;
    const res = await getTeacherOptions(filters);
    // console.log(res)
    filters.units_id = school_unit.id;
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        teacher: res,
      },
    }));
  }

  onRemoveFile() {
    this.setState((prevState) => {
      return {
        ...prevState,
        form: {
          ...prevState.form,
          value: {
            ...prevState.form.value,
            upload_kop: "",
            file: "",
          },
        },
      };
    });
  }

  async _setForm(id) {
    const { handleGetUnits, user } = this.props;
    const { organizations_id, school_unit } = user;
    try {
      const payload = await handleGetUnits({ id });
      this.setState({
        form: {
          value: {
           id: id,
           name: payload.name,
           levels_id: payload.levels_id,
           phone: payload.phone,
           address: payload.address,
           provinces: payload.provinces,
           cities: payload.cities,
           districts: payload.districts,
           sub_districts: payload.sub_districts,
           type_of_cities: payload.type_of_cities,
           village: payload.village,
           postal_code: payload.postal_code,
           unit_code: payload.unit_code,
           rayon: payload.rayon,
           website: payload.website,
           email: payload.email,
           nds: payload.nds,
           nss: payload.nss,
           npsn: payload.npsn,
           school_status: payload.school_status,
           headmaster_id: payload.headmaster_id,
           nominal_upd: payload.nominal_upd,
           namakepalasekolah: payload.namakepalasekolah,
           school_accreditation: payload.school_accreditation,
           school_time: payload.school_time,
           school_cluster: payload.school_cluster,
           school_category: payload.school_category,
           school_based_management: payload.school_based_management,
           school_curriculum: payload.school_curriculum,
           school_internet_connection: payload.school_internet_connection,
           school_boss_fund: payload.school_boss_fund,
           study_time: payload.study_time,
           study_group: payload.study_group,
           upload_logo: payload.upload_logo,
           upload_kop: payload.upload_kop,
          },
          error: {
            // details: [],
          },
        },
      });
    } catch (err) {
      // err action
    }
    finally {
      commonActions.setLoading({ show: false });
    }
  }

  render() {
    const { param, form, page } = this.state;
    const { handleUploadImageFileStudents} = this.props;
    const { provinces = [], cities = [], districts = [], subDistricts =[] } = param;
    return (
      <div className="add-budget">
        <form>
          <h1>Identitas Sekolah</h1>
          {
            (page === 1) ? (
              <IdentitasSekolah1
                form={form}
                onChange={this._onFormChange}
                onRemoveFile={this.onRemoveFile}
                param={param}
                sub_districts={subDistricts}
                districts={districts}
                cities={cities}
                provinces={provinces}
                handleUploadImageFileStudents={handleUploadImageFileStudents}
              />
            ) : (
              ''
            )
          }
          {
            (page === 2) ? (
              <IdentitasSekolah2
                form={form}
                onChange={this._onFormChange}
                param={param}
                provinces={provinces}
                onRemoveFile={this.onRemoveFile}
              />
            ) : (
              ''
            )
          }
          <div className="manage-registration__footer">
            <div className="manage-registration__button-wrapper">
              {
                page > 1 && (
                  <Button
                    onClick={this._onClickBack}
                    title="Kembali"
                  />
                )
              }
              {
                page === 1 ? (
                  this.state.id ? 
                  <Button
                    onClick={this.onEdit}
                    title="Update"
                  /> :
                  <Button
                    onClick={this._onSubmit}
                    title="Simpan"
                  />
                ) :''
              }
            </div>
          </div>
        </form>
      </div>
    );
  }
}
TambahIdentitas.propTypes = {
  getOptionsParam: PropTypes.func,
  handleGetProvinces: PropTypes.func,
  handleGetCities: PropTypes.func,
  handleGetDistricts: PropTypes.func,
  handleGetSubDistricts: PropTypes.func,
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
};
TambahIdentitas.defaultProps = {
  getOptionsParam: noop,
  handleGetProvinces: noop,
  handleGetCities: noop,
  handleGetDistricts: noop,
  handleGetSubDistricts: noop,
  user: null,
};